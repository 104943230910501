




















































































import { Component, Vue } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import CollapseTransition from '@ivanv/vue-collapse-transition'
import VsFullTopBarLayout from '@/components/VsFullTopBarLayout/Index.vue'
import MyAccordion from '@/components/MyAccordion/Index.vue'
import { UserModule } from '@/store/modules/user'
import { getImports } from '@/api/consoleApi/imports'

@Component({
    name: 'CreateContact',
    components: {
        VsContainer,
        CollapseTransition,
        VsLoader,
        VsFullTopBarLayout,
        MyAccordion,
    },
})
export default class extends Vue {
    private status = 'subscribed'
    private importMethod = 'FromFile'
    private showAdvancedMethod = false
    private totalImportsReady = 0
    $refs: any

    get listId () {
        return this.$route?.params?.listId || ''
    }

    get user () {
        return UserModule.user
    }

    get subscribersLimitRule () {
        return this.user.configuration.rules.subscribersLimit
    }

    get isSuppressionImport () {
        return this.$route.matched.find((el: any) => el.name === 'listImportIndexSuppression')
    }

    get isSynchronization () {
        return !!this.$route.matched.find((el: any) => el.name === 'createSyncIndex')
    }

    beforeMount () {
        if (this.isSynchronization) {
            this.importMethod = 'FromUrl'
        }
    }

    private async mounted () {
        if (this.subscribersLimitRule === -1) return
        try {
            const resp = await getImports({
                searchFields: 'status:in;entity_type',
                searchJoin: 'and',
                search: 'status:ready,processing;entity_type:recipient',
            })
            this.totalImportsReady = resp.data.meta.pagination.total
        } catch (e) {
            this.totalImportsReady = 0
        }
    }

    private selectMethod () {
        const prefix = this.isSynchronization ? 'createSync' : 'listImport'
        const importName = prefix + this.importMethod
        this.$router.push({
            name: !this.isSuppressionImport ? importName : importName + 'Suppression',
            params: {
                listId: this.listId,
            },
        })
    }
}
